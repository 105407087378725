import { useBlockNumbers } from '@usedapp/core';
import { useMemo, useRef } from 'react';

interface UseBlockRangeProps {
  chainId: number;
  numBlocks?: number;
  isStatic?: boolean;
}

export function useBlockRange({ chainId, numBlocks, isStatic = false }: UseBlockRangeProps) {
  // Get block numbers using the provided hook
  const blockNumbers = useBlockNumbers();

  // Extract the block number for the current chainId
  const blockNumber: number | undefined = Object.entries(blockNumbers).find((value) => {
    return value[0] === chainId.toString();
  })?.[1] as number | undefined;

  // Create refs to store static fromBlock and toBlock values
  const staticFromBlockRef = useRef<number | undefined>(undefined);
  const staticToBlockRef = useRef<number | undefined>(undefined);

  // Memoize the calculation of fromBlock
  const fromBlock = useMemo(() => {
    // If blockNumber is undefined, return undefined and don't store static values yet
    if (blockNumber === undefined) {
      return undefined;
    }

    // If isStatic is true and staticFromBlockRef has a value, use it
    if (isStatic && staticFromBlockRef.current !== undefined) {
      return staticFromBlockRef.current;
    }

    // Otherwise, calculate fromBlock
    const calculatedFromBlock =
      numBlocks === undefined ? 0 : Math.max(0, blockNumber - numBlocks + 1);

    // If isStatic is true, store the calculated value in the ref
    if (isStatic) {
      staticFromBlockRef.current = calculatedFromBlock;
    }

    return calculatedFromBlock;
  }, [isStatic, numBlocks, blockNumber, chainId]);

  // Memoize the toBlock as the current blockNumber
  const toBlock = useMemo(() => {
    // If blockNumber is undefined, return undefined and don't store static values yet
    if (blockNumber === undefined) {
      return undefined;
    }

    // If isStatic is true and staticToBlockRef has a value, use it
    if (isStatic && staticToBlockRef.current !== undefined) {
      return staticToBlockRef.current;
    }

    // Otherwise, return the blockNumber
    const calculatedToBlock = blockNumber;

    // If isStatic is true, store the calculated value in the ref
    if (isStatic) {
      staticToBlockRef.current = calculatedToBlock;
    }

    return calculatedToBlock;
  }, [isStatic, blockNumber, chainId]);

  // Return both fromBlock and toBlock
  return { fromBlock, toBlock };
}
