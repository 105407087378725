import {
  CardContent,
  CardHeader,
  CardHeaderProps,
  Collapse,
  CollapseProps,
  ListItemButton,
  Paper,
  useTheme,
} from '@mui/material';
import { useBoolean } from 'src/hooks/use-boolean';
import Iconify from './Iconify';
import { PaperProps } from '@mui/material';

interface CollapsablePaperProps extends PaperProps {
  component?: React.ElementType;
  cardHeaderProps?: CardHeaderProps;
  collapseProps?: CollapseProps;
  startExpanded?: boolean;
  children?: React.ReactNode;
  actions?: React.ReactNode;
}

export default function CollapsablePaper({
  component: Component = Paper,
  cardHeaderProps,
  collapseProps,
  startExpanded,
  children,
  ...other
}: CollapsablePaperProps) {
  const theme = useTheme();
  const expanded = useBoolean(startExpanded);

  return (
    <Component variant={other?.variant ?? 'outlined'} {...other}>
      <CardHeader
        component={ListItemButton}
        onClick={expanded.onToggle}
        avatar={
          <Iconify
            icon={expanded.value ? 'eva:arrow-ios-upward-fill' : 'eva:arrow-ios-downward-fill'}
          />
        }
        sx={{
          paddingBottom: theme.spacing(3),
        }}
        {...cardHeaderProps}
      />
      <Collapse in={expanded.value} {...collapseProps}>
        <CardContent sx={{ paddingTop: 0, marginTop: theme.spacing(1) }}>{children}</CardContent>
        {!!other?.actions && other.actions}
      </Collapse>
    </Component>
  );
}
