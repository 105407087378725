import { ethers } from 'ethers';

export function addressValidator(allowEmpty = false) {
  return (value: any) => {
    if (value === '' && allowEmpty) {
      return true;
    }

    if (value == null) {
      return false;
    }

    try {
      ethers.utils.getAddress(value);

      return true;
    } catch (error) {
      return false;
    }
  };
}

export function addressEqualsValidator(address: string) {
  return (value: any) => {
    if (value == null) {
      return false;
    }

    try {
      ethers.utils.getAddress(value);

      return value === address;
    } catch (error) {
      return false;
    }
  };
}

export function notZeroAddressValidator(allowEmpty = false) {
  return (value: any) => {
    if (value === '' && allowEmpty) {
      return true;
    }

    if (value == null) {
      return false;
    }

    try {
      const address = ethers.utils.getAddress(value);

      return address !== ethers.constants.AddressZero;
    } catch (error) {
      return false;
    }
  };
}
