import { Suspense, lazy, ElementType } from 'react';
import { Navigate, useRoutes, useLocation, Outlet } from 'react-router-dom';
// layouts
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// components
import LoadingScreen from '../components/LoadingScreen';
import MainLayout from 'src/layouts/main';
import ControllerView from 'src/pages/controller-view';
import ControllerFeeds from 'src/pages/controller-feeds';
import ControllerAssetView from 'src/pages/controller-asset-view';

// ----------------------------------------------------------------------

const Loadable = (Component: ElementType) => (props: any) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  return (
    <Suspense fallback={<LoadingScreen isDashboard={pathname.includes('/app')} />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      element: (
        <MainLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </MainLayout>
      ),
      children: [
        {
          element: <Home />,
          index: true,
        },
        {
          path: 'about',
          element: <About />,
        },
      ],
    },
    {
      path: '/app',
      element: <Dashboard />,
      children: [
        { element: <Navigate to="/app/oracles" replace />, index: true },
        {
          path: 'oracles',
          children: [
            { element: <Navigate to="/app/oracles/arbitrumOne" replace />, index: true },
            {
              path: ':networkName/:oracleAddress/:assetAddress',
              element: <OracleAssetView />,
            },
            {
              path: ':networkName/:oracleAddress',
              element: <OracleView />,
            },
            {
              path: ':networkName',
              element: <DataFeeds />,
            },
          ],
        },
        {
          path: 'controllers',
          children: [
            {
              path: ':networkName/:oracleAddress/:assetAddress',
              element: <ControllerAssetView />,
            },
            {
              path: ':networkName/:oracleAddress',
              element: <ControllerView />,
            },
            {
              path: ':networkName',
              element: <ControllerFeeds />,
            },
          ],
        },
        {
          path: 'updaters',
          children: [
            {
              path: ':networkName',
              element: <Updaters />,
            },
          ],
        },
      ],
    },
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}

const Home = Loadable(lazy(() => import('../pages/Home')));
const About = Loadable(lazy(() => import('../pages/About')));
const Dashboard = Loadable(lazy(() => import('../layouts/dashboard')));

// Dashboard
const OracleAssetView = Loadable(lazy(() => import('../pages/OracleAssetView')));
const OracleView = Loadable(lazy(() => import('../pages/OracleView')));
const DataFeeds = Loadable(lazy(() => import('../pages/DataFeeds')));
const Updaters = Loadable(lazy(() => import('../pages/Updaters')));
const NotFound = Loadable(lazy(() => import('../pages/Page404')));
