import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  styled,
  useTheme,
} from '@mui/material';
import { Call, ChainId, useCall, useCalls, useEthers } from '@usedapp/core';
import { IAccessControlEnumerable } from 'typechain/openzeppelin-v4';
import AddressDisplay from '../AddressDisplay';
import { AddressZero } from '@ethersproject/constants';
import { useBoolean } from 'src/hooks/use-boolean';
import { useMemo, useState } from 'react';
import RevokeRoleDialog from 'src/dialogs/access-control/revoke-role-dialog';
import { StandardRoleManagement } from 'typechain/automatos-protocol';
import { default as standardRoleManagementAbi } from '../../abi/automatos-protocol/contracts/access/StandardRoleManagement.sol/StandardRoleManagement.json';
import { Interface } from 'ethers/lib/utils';
import { Contract } from 'ethers';
import RenounceRoleDialog from 'src/dialogs/access-control/renounce-role-dialog';

const stdRoleManagementInterface = new Interface(standardRoleManagementAbi.abi);

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(even)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
  width: '50%',
}));

type AccessControlTableProps = {
  contract: IAccessControlEnumerable | undefined;
  chainId: ChainId;
  role: string;
};

export default function AccessControlTable({ contract, chainId, role }: AccessControlTableProps) {
  const theme = useTheme();

  const contractStdRoleManagement = useMemo(() => {
    return contract == null
      ? undefined
      : new Contract(contract.address, stdRoleManagementInterface);
  }, [contract?.address]) as StandardRoleManagement;

  const { account } = useEthers();

  const revokeRoleDialogOpen = useBoolean();
  const renounceRoleDialogOpen = useBoolean();
  const [address, setAddress] = useState('');

  const roleManagementAddressCall = useCall(
    contractStdRoleManagement && {
      contract: contractStdRoleManagement,
      method: 'getRoleManagementAddress',
      args: [role],
    },
    {
      chainId: chainId,
    }
  );
  const roleManagementAddress = roleManagementAddressCall?.value?.[0];

  const adminRoleCall = useCall(
    contract && {
      contract: contract,
      method: 'getRoleAdmin',
      args: [role],
    },
    {
      chainId: chainId,
    }
  );
  const adminRole = adminRoleCall?.value?.[0];

  const membersCount = useCall(
    contract && {
      contract: contract,
      method: 'getRoleMemberCount',
      args: [role],
    },
    {
      chainId: chainId,
    }
  );
  const addressesCalls = new Array<Call | undefined>(membersCount?.value?.[0]?.toNumber() ?? 0);
  for (var i = 0; i < addressesCalls.length; ++i) {
    addressesCalls[i] = contract && {
      contract: contract,
      method: 'getRoleMember',
      args: [role, i],
    };
  }
  const addresses = useCalls(addressesCalls, {
    chainId: chainId,
  });

  const memberCountLoading = membersCount?.value?.[0] == null && membersCount?.error == null;
  const membersLoading =
    membersCount?.value?.[0] != null &&
    membersCount?.value?.[0]?.gt(0) &&
    addresses.some((call) => call?.value == null);
  const isLoading = memberCountLoading || membersLoading;
  const noMembers = !isLoading && membersCount?.value?.[0]?.eq(0);

  const isRoleAdminCall = useCall(
    contract &&
      account &&
      adminRole && {
        contract: contract,
        method: 'hasRole',
        args: [adminRole, account],
      },
    {
      chainId: chainId,
    }
  );
  const isRoleAdmin = isRoleAdminCall?.value?.[0];

  if (roleManagementAddress && roleManagementAddress !== contract?.address) {
    return (
      <Typography variant="body2" align="center" component="span">
        Managed by contract:{' '}
        <AddressDisplay
          address={roleManagementAddress}
          chainId={chainId}
          resolveAddress
          hideAddressIfResolved
          component="span"
        />
      </Typography>
    );
  }

  if (adminRole === '0x0000000000000000000000000000000000000000000000000000000000000000') {
    return (
      <Typography variant="body2" align="center">
        Not supported
      </Typography>
    );
  }

  if (noMembers) {
    return (
      <Typography variant="body2" align="center">
        No members
      </Typography>
    );
  }

  return (
    <>
      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell width="50%" align="center">
                Address
              </TableCell>
              <TableCell width="50%" align="center">
                Actions
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading ? (
              <>
                <StyledTableRow>
                  <TableCell align="center">Loading...</TableCell>
                  <TableCell align="center"></TableCell>
                </StyledTableRow>
              </>
            ) : (
              <>
                {addresses.map((callResult, i) => (
                  <StyledTableRow key={i}>
                    <TableCell align="center">
                      {callResult?.value?.[0] === AddressZero ? (
                        <Typography variant="body2">Anyone</Typography>
                      ) : (
                        <AddressDisplay
                          address={callResult?.value?.[0]}
                          chainId={chainId}
                          resolveAddress
                          hideAddressIfResolved
                        />
                      )}
                    </TableCell>
                    <TableCell align="center">
                      {account && isRoleAdmin && (
                        <Button
                          variant="outlined"
                          size="small"
                          sx={{
                            margin: theme.spacing(1),
                          }}
                          onClick={() => {
                            setAddress(callResult?.value?.[0]);
                            revokeRoleDialogOpen.onTrue();
                          }}
                        >
                          Revoke role
                        </Button>
                      )}
                      {account && account === callResult?.value?.[0] && (
                        <Button
                          variant="outlined"
                          size="small"
                          sx={{
                            margin: theme.spacing(1),
                          }}
                          onClick={() => {
                            renounceRoleDialogOpen.onTrue();
                          }}
                        >
                          Renounce role
                        </Button>
                      )}
                    </TableCell>
                  </StyledTableRow>
                ))}
              </>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {contract && (
        <RevokeRoleDialog
          dialogOpen={revokeRoleDialogOpen}
          contractAddress={contract.address}
          address={address}
          role={role}
          chainId={chainId}
        />
      )}
      {contract && (
        <RenounceRoleDialog
          dialogOpen={renounceRoleDialogOpen}
          contractAddress={contract.address}
          role={role}
          chainId={chainId}
        />
      )}
    </>
  );
}
