import { Card, CardContent, CardHeader } from '@mui/material';
import AccessControlCardContent from './AccessControlCardContent';
import CollapsablePaper from '../CollapsablePaper';

type AccessControlCardProps = {
  contractAddress: string;
  networkName: string;
};

export default function AccessControlCard({
  contractAddress,
  networkName,
}: AccessControlCardProps) {
  return (
    <>
      <CollapsablePaper
        component={Card}
        variant="elevation"
        cardHeaderProps={{ title: 'Access control' }}
      >
        <AccessControlCardContent contractAddress={contractAddress} networkName={networkName} />
      </CollapsablePaper>
    </>
  );
}
