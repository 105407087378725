import {
  Alert,
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Link,
  Paper,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import AccessControlTable from './AccessControlTable';
import {
  ADMIN,
  CONFIG_ADMIN,
  FACTORY_ADMIN,
  FACTORY_MANAGER,
  FACTORY_REGISTRY_DEPLOYER,
  POOL_ADMIN,
  POOL_MANAGER,
  POOL_WORK_MANAGER,
  PROTOCOL_ADMIN,
  RATE_ADMIN,
  REGISTRY_ADMIN,
  REGISTRY_FINANCE_MANAGER,
  REGISTRY_MANAGER,
  REGISTRY_POOL_DEPLOYER,
  ROLES,
  TARGET_ADMIN,
  UPDATER,
  UPDATER_ADMIN,
  UPDATE_PAUSE_ADMIN,
  VOID_ROLE,
  WORKER,
  WORKER_ADMIN,
  WORKER_MANAGER,
} from 'src/constants/roles';

import { default as accessControlEnumerableAbi } from '@openzeppelin-v4/contracts/build/contracts/AccessControlEnumerable.json';
import { Contract, utils } from 'ethers';
import { IAccessControlEnumerable } from 'typechain/openzeppelin-v4';
import { useBoolean } from 'src/hooks/use-boolean';
import { useCalls, useEthers } from '@usedapp/core';
import GrantRoleDialog from 'src/dialogs/access-control/grant-role-dialog';
import Iconify from '../Iconify';
import { PATH_DISCORD } from 'src/routes/paths';
import config from 'src/adrastia.config';
import OrganizationalChart from '../organizational-chart/organizational-chart';
import CollapsablePaper from '../CollapsablePaper';
import RoleGrantedTable from './RoleGrantedTable';
import RoleRevokedTable from './RoleRevokedTable';

const accessControlEnumerableInterface = new utils.Interface(accessControlEnumerableAbi.abi);

type AccessControlCardContentProps = {
  contractAddress: string;
  networkName: string;
  hideAlert?: boolean;
};

export default function AccessControlCardContent({
  contractAddress,
  networkName,
  hideAlert,
}: AccessControlCardContentProps) {
  const contractAccessControlEnumerable = new Contract(
    contractAddress,
    accessControlEnumerableInterface
  ) as IAccessControlEnumerable;
  const theme = useTheme();
  const lightMode = theme.palette.mode === 'light';

  const { account } = useEthers();

  const grantRoleDialogOpen = useBoolean();

  const possibleRoles = [
    ADMIN,
    CONFIG_ADMIN,
    RATE_ADMIN,
    TARGET_ADMIN,
    UPDATER,
    UPDATER_ADMIN,
    UPDATE_PAUSE_ADMIN,
    PROTOCOL_ADMIN,
    FACTORY_ADMIN,
    FACTORY_MANAGER,
    FACTORY_REGISTRY_DEPLOYER,
    REGISTRY_ADMIN,
    REGISTRY_FINANCE_MANAGER,
    REGISTRY_MANAGER,
    REGISTRY_POOL_DEPLOYER,
    WORKER_ADMIN,
    WORKER_MANAGER,
    WORKER,
    POOL_ADMIN,
    POOL_MANAGER,
    POOL_WORK_MANAGER,
  ];

  const roleAdminCalls_ = possibleRoles.map((role) => {
    return {
      contract: contractAccessControlEnumerable,
      method: 'getRoleAdmin',
      args: [role],
    };
  });
  const roleAdminCalls = useCalls(roleAdminCalls_, {
    chainId: config.chains[networkName]?.chainId,
  });
  const roles = roleAdminCalls?.map((roleAdminCall, index) => {
    return {
      role: possibleRoles[index],
      adminCall: roleAdminCall,
      mapped: false,
    };
  });

  type BaseNode = {
    name: string;
    role: string;
  };

  type Node = BaseNode & {
    children: Node[];
  };

  const roleHierarchyRoots: Node[] = [];

  // Roots are roles whose admin is itself
  for (const role of roles) {
    if (role?.adminCall?.value?.[0] === role.role) {
      roleHierarchyRoots.push({
        name: ROLES[role.role]?.name ?? 'Unknown role',
        role: role.role,
        children: undefined as any,
      });

      role.mapped = true;
    }
  }

  // Map all children of the roots, one depth at a time up to 10 levels deep
  const findNode = (role: string, node?: Node): Node | undefined => {
    // If no node is passed, start from the root nodes
    const nodesToSearch = node ? [node] : roleHierarchyRoots;

    for (const nodeToSearch of nodesToSearch) {
      if (nodeToSearch.role === role) {
        return nodeToSearch;
      }

      if (nodeToSearch.children && nodeToSearch.children.length > 0) {
        // Recursively search through all children
        for (const child of nodeToSearch.children) {
          const foundNode = findNode(role, child);
          if (foundNode) {
            return foundNode;
          }
        }
      }
    }

    return undefined;
  };
  let mapped = false;
  for (let i = 0; i < 10; i++) {
    for (const role of roles) {
      if (role.mapped) {
        continue;
      }

      const admin = role.adminCall?.value?.[0];
      if (!admin) {
        continue;
      }

      const adminNode = findNode(admin);
      if (adminNode) {
        if (!adminNode.children) {
          adminNode.children = [];
        }

        adminNode.children.push({
          name: ROLES[role.role]?.name ?? 'Unknown role',
          role: role.role,
          children: undefined as any,
        });

        role.mapped = true;
        mapped = true;
      }
    }

    if (!mapped) {
      break;
    }
  }

  return (
    <>
      <Grid container spacing={2}>
        {!hideAlert && (
          <Grid item xs={12} marginBottom={theme.spacing(4)}>
            <Alert icon={<Iconify icon="mdi:wand" />} severity="info">
              Not comfortable with the permissioning? Reach out to us on{' '}
              <Link
                href={PATH_DISCORD}
                target="_blank"
                color={lightMode ? 'primary.dark' : 'primary.light'}
                fontWeight={600}
              >
                Discord
              </Link>{' '}
              or by email at{' '}
              <Link
                href="mailto:support@adrastia.io"
                target="_blank"
                color={lightMode ? 'primary.dark' : 'primary.light'}
                fontWeight={600}
              >
                support@adrastia.io
              </Link>{' '}
              and we'll deploy a custom solution for you with permissioning that fits your needs.
            </Alert>
          </Grid>
        )}

        <Grid item xs={12}>
          <CollapsablePaper
            cardHeaderProps={{ title: 'Administration' }}
            collapseProps={{ unmountOnExit: true }}
          >
            <Tooltip title={!account ? 'Please connect your wallet.' : ''}>
              <span>
                <Button
                  sx={{
                    margin: theme.spacing(1),
                  }}
                  variant="contained"
                  fullWidth
                  disabled={!account}
                  onClick={() => {
                    grantRoleDialogOpen.onTrue();
                  }}
                >
                  Grant role
                </Button>
              </span>
            </Tooltip>
          </CollapsablePaper>
        </Grid>
        <Grid item xs={12}>
          <CollapsablePaper
            cardHeaderProps={{ title: 'Role hierarchy' }}
            collapseProps={{ unmountOnExit: true }}
          >
            {roleHierarchyRoots.map((roleHierarchyRoot, index) => (
              <OrganizationalChart
                data={roleHierarchyRoot}
                lineColor={theme.palette.primary.light}
                key={index}
              />
            ))}
          </CollapsablePaper>
        </Grid>
        <Grid item xs={12}>
          <CollapsablePaper
            cardHeaderProps={{ title: 'Role membership' }}
            collapseProps={{ unmountOnExit: true }}
          >
            <Grid container spacing={theme.spacing(2)}>
              {roles?.map(
                (role, index) =>
                  role.adminCall?.value?.[0] &&
                  role.adminCall?.value?.[0] != VOID_ROLE && (
                    <Grid item xs={12} alignContent="center" key={index}>
                      <Paper variant="outlined">
                        <CardHeader
                          title={ROLES[role.role]?.name ?? 'Unknown role'}
                          subheader={`Role hash: ${role.role}`}
                        />
                        <CardContent>
                          <AccessControlTable
                            contract={contractAccessControlEnumerable}
                            role={role.role}
                            chainId={config.chains[networkName]?.chainId}
                          />
                        </CardContent>
                      </Paper>
                    </Grid>
                  )
              )}
            </Grid>
          </CollapsablePaper>
        </Grid>
        <Grid item xs={12}>
          <CollapsablePaper
            cardHeaderProps={{ title: 'Events - Role granted' }}
            collapseProps={{ unmountOnExit: true }}
          >
            <RoleGrantedTable contractAddress={contractAddress} networkName={networkName} />
          </CollapsablePaper>
        </Grid>
        <Grid item xs={12}>
          <CollapsablePaper
            cardHeaderProps={{ title: 'Events - Role revoked' }}
            collapseProps={{ unmountOnExit: true }}
          >
            <RoleRevokedTable contractAddress={contractAddress} networkName={networkName} />
          </CollapsablePaper>
        </Grid>
      </Grid>
      <GrantRoleDialog
        contractAddress={contractAddress}
        networkName={networkName}
        dialogOpen={grantRoleDialogOpen}
      />
    </>
  );
}
