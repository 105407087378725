import AddressDisplay from '../AddressDisplay';
import { default as standardRoleManagementAbi } from '../../abi/automatos-protocol/contracts/access/StandardRoleManagement.sol/StandardRoleManagement.json';
import config from 'src/adrastia.config';
import { commify, Interface } from 'ethers/lib/utils';
import { useMemo, useState } from 'react';
import { Contract } from 'ethers';
import { StandardRoleManagement } from 'typechain/automatos-protocol';
import { useLogs } from '@usedapp/core';
import {
  DataGridPremium as DataGrid,
  GRID_AGGREGATION_FUNCTIONS,
  GridColDef,
  GridColumnVisibilityModel,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
  GridToolbarQuickFilter,
} from '@mui/x-data-grid-premium';
import EmptyContent from '../empty-content';
import { Typography } from '@mui/material';
import { useBlockRange } from 'src/hooks/use-block-range';
import { ROLES } from 'src/constants/roles';

const managementInterface = new Interface(standardRoleManagementAbi.abi);

const HIDE_COLUMNS = {
  id: false,
};

type RoleRevokedTableProps = {
  contractAddress: string;
  networkName: string;
};

export default function RoleRevokedTable({ contractAddress, networkName }: RoleRevokedTableProps) {
  const chainId = config.chains[networkName].chainId;

  const baseColumns: GridColDef[] = useMemo(
    () => [
      {
        field: 'id',
        headerName: 'Id',
        filterable: false,
      },
      {
        field: 'blockNumber',
        headerName: 'Block number',
        type: 'number',
        availableAggregationFunctions: ['max', 'min', 'avg', 'size'],
      },
      {
        field: 'txHash',
        headerName: 'Tx hash',
        availableAggregationFunctions: ['size'],
        flex: 1,
      },
      {
        field: 'roleHash',
        headerName: 'Role hash',
        availableAggregationFunctions: ['size'],
      },
      {
        field: 'roleName',
        headerName: 'Role name',
        availableAggregationFunctions: ['size'],
        flex: 2,
      },
      {
        field: 'account',
        headerName: 'Account address',
        availableAggregationFunctions: ['size'],
        minWidth: 160,
        renderCell: (params) => {
          if (!params?.value) {
            return <></>;
          }

          return (
            <AddressDisplay
              address={params.value as string}
              networkName={networkName}
              chainId={chainId}
              resolveAddress
              hideAddressIfResolved
            />
          );
        },
      },
      {
        field: 'sender',
        headerName: 'Sender address',
        availableAggregationFunctions: ['size'],
        minWidth: 160,
        renderCell: (params) => {
          if (!params?.value) {
            return <></>;
          }

          return (
            <AddressDisplay
              address={params.value as string}
              networkName={networkName}
              chainId={chainId}
              resolveAddress
              hideAddressIfResolved
            />
          );
        },
      },
    ],
    [chainId, networkName]
  );

  const contract = useMemo(
    () => new Contract(contractAddress, managementInterface),
    [contractAddress]
  ) as StandardRoleManagement;

  const { fromBlock, toBlock } = useBlockRange({
    chainId: config.chains[networkName].chainId,
    numBlocks: 10_000_000,
    isStatic: true,
  });

  const logs = useLogs(
    fromBlock != null &&
      toBlock != null && {
        contract: contract,
        event: 'RoleRevoked(bytes32,address,address)',
        args: [],
      },
    {
      chainId: chainId,
      isStatic: true,
      refresh: 'never',
      fromBlock: fromBlock,
      toBlock: toBlock,
    }
  );

  const isLoading = logs?.value == null && logs?.error == null;
  const isError = logs?.error != null;

  const [columnVisibilityModel, setColumnVisibilityModel] =
    useState<GridColumnVisibilityModel>(HIDE_COLUMNS);

  const rows =
    logs?.value
      ?.map((log, index) => {
        const { blockNumber, transactionHash, data } = log;

        return {
          id: index.toString(),
          blockNumber: blockNumber,
          txHash: transactionHash,
          roleHash: data.role,
          roleName: ROLES[data.role]?.name ?? '',
          account: data.account,
          sender: data.sender,
        };
      })
      ?.reverse() ?? [];

  const gridMemo = useMemo(
    () => (
      <DataGrid
        sx={{ '--DataGrid-overlayHeight': '250px' }}
        autoHeight
        checkboxSelection
        pagination
        pageSizeOptions={[10, 25, 50, 100]}
        rows={rows}
        columns={baseColumns}
        columnVisibilityModel={columnVisibilityModel}
        onColumnVisibilityModelChange={(newModel) => setColumnVisibilityModel(newModel)}
        slots={{
          toolbar: CustomToolbar,
          noRowsOverlay: () => {
            if (isLoading) {
              return <EmptyContent title="Loading..." />;
            } else if (isError) {
              return <EmptyContent title="Error loading data" />;
            } else return <EmptyContent title="No data" />;
          },
          noResultsOverlay: () => <EmptyContent title="No results found" />,
        }}
        slotProps={{
          toolbar: {
            showQuickFilter: true,
          },
        }}
        aggregationFunctions={GRID_AGGREGATION_FUNCTIONS}
        initialState={{
          pagination: { paginationModel: { pageSize: 10 } },
        }}
      />
    ),
    [!!logs?.value]
  );

  return (
    <>
      {gridMemo}
      {fromBlock != null && toBlock != null && (
        <Typography variant="caption">{`Showing events from the past ${commify(
          toBlock - fromBlock + 1
        )} blocks (from ${fromBlock} to ${toBlock})`}</Typography>
      )}
    </>
  );
}

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarQuickFilter sx={{ flexGrow: 1 }} />
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
      <GridToolbarExport />
    </GridToolbarContainer>
  );
}
