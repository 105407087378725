import { format, getTime, formatDistanceToNow } from 'date-fns';
import { format as tzFormat } from 'date-fns-tz';

// ----------------------------------------------------------------------

export function fDate(date: Date | string | number) {
  return format(new Date(date), 'dd MMMM yyyy');
}

export function fDateTime(date: Date | string | number) {
  return tzFormat(new Date(date), 'dd MMM yyyy p zzz');
}

export function fTimestamp(date: Date | string | number) {
  return getTime(new Date(date));
}

export function fDateTimeSuffix(date: Date | string | number) {
  return format(new Date(date), 'dd/MM/yyyy hh:mm p');
}

export function fToNow(date: Date | string | number) {
  return formatDistanceToNow(new Date(date), {
    addSuffix: true,
  });
}

function secondsToHms(d: number) {
  return msToHrf(d * 1000);
}

function msToHrf(d: number) {
  var days = Math.floor(d / (3600 * 24 * 1000));
  var h = Math.floor((d % (3600 * 24 * 1000)) / (3600 * 1000));
  var m = Math.floor((d % (3600 * 1000)) / (60 * 1000));
  var s = Math.floor((d % (60 * 1000)) / 1000);
  var ms = Math.floor(d % 1000);

  var daysDisplay = days > 0 ? days + (days == 1 ? ' day, ' : ' days, ') : '';
  var hDisplay = h > 0 ? h + (h == 1 ? ' hour, ' : ' hours, ') : '';
  var mDisplay = m > 0 ? m + (m == 1 ? ' minute, ' : ' minutes, ') : '';
  var sDisplay = s > 0 ? s + (s == 1 ? ' second, ' : ' seconds, ') : '';
  var msDisplay = ms > 0 ? ms + (ms == 1 ? ' millisecond' : ' milliseconds') : '';

  var result = daysDisplay + hDisplay + mDisplay + sDisplay + msDisplay;

  // Remove the last comma and space if any
  if (result.endsWith(', ')) {
    result = result.substring(0, result.length - 2);
  }

  // If the result is still empty, return '0 milliseconds'
  if (result === '') {
    result = '0 milliseconds';
  }

  return result;
}

export function fDuration(seconds: number) {
  return secondsToHms(seconds);
}

export function fDurationMs(ms: number) {
  return msToHrf(ms);
}

export function monthsSince(dateString: string): number {
  const specifiedDate = new Date(dateString);
  const currentDate = new Date();

  // Calculate the difference in months
  const yearsDifference = currentDate.getFullYear() - specifiedDate.getFullYear();
  const monthsDifference = currentDate.getMonth() - specifiedDate.getMonth();
  const totalMonthsDifference = yearsDifference * 12 + monthsDifference;

  // Adjust if the day of the month of the current date is less than the day of the month of the specified date
  if (currentDate.getDate() < specifiedDate.getDate()) {
    return totalMonthsDifference - 1;
  }

  return totalMonthsDifference;
}
